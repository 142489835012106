import Parse from "parse";

















export class Technican extends Parse.Object {
  static __initStatic() {this.className = "Heinzerling2_Technican"}

  constructor(data) {
    super("Heinzerling2_Technican", data );
  }

  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get name2() {
    return super.get("name2");
  }
  set name2(value) {
    super.set("name2", value);
  }
  get no() {
    return super.get("no");
  }
  set no(value) {
    super.set("no", value);
  }
  get searchIndex() {
    return super.get("searchIndex");
  }
  set searchIndex(value) {
    super.set("searchIndex", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted() {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value) {
    super.set("sqlDeleted", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
} Technican.__initStatic();

Parse.Object.registerSubclass("Heinzerling2_Technican", Technican);
