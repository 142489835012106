 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable, runInAction } from "@opendash/state";

import dayjs from "dayjs";
import {

  OrderItemRelation,
  OrderTechnicanRelation,


} from "../../../parse";
import { $heinzerling } from "../../../service";


const DATE_FORMAT = "YYYY-MM-DD";

export class WeekOverviewState {
   __init() {this.loading = true}
   __init2() {this.tab = "orders"}

   __init3() {this.start = dayjs().startOf("isoWeek")}
   __init4() {this.end = dayjs().endOf("isoWeek")}

   __init5() {this.showFinished = false}
   __init6() {this.showOpen = true}
   __init7() {this.showOwnOrdersOnly = true}

   __init8() {this.orders = []}
   __init9() {this.items = []}

  constructor() {;WeekOverviewState.prototype.__init.call(this);WeekOverviewState.prototype.__init2.call(this);WeekOverviewState.prototype.__init3.call(this);WeekOverviewState.prototype.__init4.call(this);WeekOverviewState.prototype.__init5.call(this);WeekOverviewState.prototype.__init6.call(this);WeekOverviewState.prototype.__init7.call(this);WeekOverviewState.prototype.__init8.call(this);WeekOverviewState.prototype.__init9.call(this);
    makeAutoObservable(this);
  }

  setTab(tab) {
    this.tab = tab;
  }

  setTimerange(start, end) {
    this.start = start;
    this.end = end;
  }

  setShowOpen(value) {
    this.showOpen = value;
  }

  setShowFinished(value) {
    this.showFinished = value;
  }
  setShowOwnOrdersOnly(value) {
    this.showOwnOrdersOnly = value;
  }

  setParams(
    startParam,
    endParam,
    showFinised,
    showOpen,
    showOwnOrdersOnly
  ) {
    this.start = startParam
      ? dayjs(startParam, DATE_FORMAT)
      : dayjs().startOf("isoWeek");

    this.end = endParam
      ? dayjs(endParam, DATE_FORMAT)
      : dayjs().endOf("isoWeek");

    !this.showOpen ? (this.showOpen = showOpen) : null;
    this.showFinished = showFinised;
    !this.showOwnOrdersOnly
      ? (this.showOwnOrdersOnly = showOwnOrdersOnly)
      : null;
    this.load();
  }

   async load() {
    runInAction(() => {
      this.loading = true;
      this.orders = [];
      this.items = [];
    });

    const orders = {};

    const orderTechnicanRelationsQuery = $heinzerling.sync
      .createQuery(OrderTechnicanRelation)
      .exists("start")
      .descending("start")
      .greaterThan("start", this.start.toDate())
      .lessThan("start", this.end.toDate())
      .include("order")
      .include("technican")
      // @ts-ignore
      .include("order.customer")
      // @ts-ignore
      .include("order.facility")
      .limit(1000000);

    if (this.showOwnOrdersOnly) {
      orderTechnicanRelationsQuery.equalTo(
        "technican",
        ((await $heinzerling.sync.getUser()) ).get(
          "technican"
        ) 
      );
    }

    const orderTechnicanRelations = await orderTechnicanRelationsQuery.find();

    for (const otr of orderTechnicanRelations) {
      const order = otr.get("order");

      if (!order) {
        continue;
      }

      if (
        !this.showOpen &&
        !(order.get("sys_locked") || order.get("sys_mailSent"))
      ) {
        continue;
      }

      if (
        !this.showFinished &&
        (order.get("sys_locked") || order.get("sys_mailSent"))
      ) {
        continue;
      }

      if (orders[order.id]) {
        continue;
      }

      orders[order.id] = otr;
    }

    const orderItems = await $heinzerling.sync
      .createQuery(OrderItemRelation)
      .containedIn(
        "order",
        Object.values(orders).map((item) => item.get("order") || "noOrder")
      )
      .doesNotExist("parentPosition")
      .include("item")
      .limit(1000000)
      .find();

    const itemsMap = {};

    for (const iterator of orderItems) {
      const item = iterator.get("item") ;
      const quantity = iterator.get("quantity");
      const orderNo = _optionalChain([iterator, 'access', _ => _.get, 'call', _2 => _2("order"), 'optionalAccess', _3 => _3.get, 'call', _4 => _4("no")]) || "";
      const fromSync = iterator.get("fromSync");

      if (
        !item ||
        !orderNo ||
        !quantity ||
        !item.get("inPlanView") ||
        !fromSync
      ) {
        continue;
      }

      if (itemsMap[item.id]) {
        itemsMap[item.id].quantity += quantity;
        itemsMap[item.id].description += ", " + orderNo;
      } else {
        const name = item.get("name");
        const no = item.get("no");
        const key = item.id;
        const title = `${name} (#${no})`;
        const description = orderNo;
        const body = item.get("description4") || "";
        const unit = item.get("unit");

        itemsMap[item.id] = { key, title, description, body, quantity, unit };
      }
    }

    runInAction(() => {
      this.loading = false;
      this.orders = Object.values(orders);
      this.items = Object.values(itemsMap);
    });
  }
}
