const _jsxFileName = "/home/runner/work/heinzerling-web/heinzerling-web/app/js/features/orders/components/OrdersList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";

import { useElementSize, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Checkbox, Divider, List, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import {
  formatAddressWithName,
  formatDate,
  formatDateTime,
} from "../../../helper/formatter";
import { Order, OrderTechnicanRelation } from "../../../parse";
import { $heinzerling } from "../../../service";

export default observer(
  ({ rows, loading, hideElevatorLink, linkToMobile, allowSelection }) => {
    const t = useTranslation();
    const navigate = useNavigate();
    const listRef = React.useRef(null);
    const { width, height } = useElementSize(listRef, 200);
    const [selectedOrders, setSelectedOrders] = React.useState(
      []
    );

    const getRowKey = (row) => {
      if (row instanceof Order) {
        return row.id + "_" + crypto.randomUUID();
      }
      if (row instanceof OrderTechnicanRelation) {
        return row.id + row.get("sys_app_id");
      }
      return crypto.randomUUID();
    };

    return (
      React.createElement('div', { ref: listRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , React.createElement(List, {
          itemLayout: width < 600 ? "vertical" : "horizontal",
          loading: loading,
          dataSource: rows,
          locale: {
            emptyText: t("app:orders.list_empty"),
          },
          rowKey: (row) => getRowKey(row),
          header: 
            !allowSelection ? undefined : (
              React.createElement('div', { style: { display: "flex", justifyContent: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
                , React.createElement(Button, {
                  key: "sign",
                  type: "primary",
                  icon: React.createElement(Icon, { icon: "fa:signature", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} ),
                  children: t("app:admin.order.action_sign"),
                  disabled: selectedOrders.length === 0,
                  onClick: () => {
                    $heinzerling.mobile.signOrders(selectedOrders);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
                )
              )
            )
          ,
          footer: 
            React.createElement(React.Fragment, null
              , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
              , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
                , React.createElement(Icon, { icon: "fa:check-double", style: { color: "green" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} ), " = Arbeiten abgeschlossen + Arbeitsbericht versandt"

              )
              , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                , React.createElement(Icon, { icon: "fa:check", style: { color: "#ffc107" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} ), " = Arbeitsbericht versandt"

              )
              /* <div>
            <Icon icon="fa:clock" /> = Keine der oberen Bedingungen
          </div> */
            )
          ,
          renderItem: (row) => {
            const auftrag = getOrder(row);
            const facility = getFacility(row);
            const customer = auftrag.get("customer") ;
            const title = "#" + auftrag.no;
            const description1 = auftrag.documentText;
            const description2 = formatAddressWithName(facility || customer);
            const reldescription = getDescription(row);
            const date = getDate(row);
            const link = getLink(row, linkToMobile, auftrag);
            const technician = getTechnician(row);
            const checkboxDisabled = !!auftrag.get("sys_mailSent");

            return (
              React.createElement(List.Item, {
                onClick: 
                  checkboxDisabled
                    ? undefined
                    : (e) => {
                        e.preventDefault();
                        if (selectedOrders && setSelectedOrders) {
                          if (selectedOrders.includes(auftrag.id)) {
                            // Orderalready exists in selected orders
                            setSelectedOrders(
                              selectedOrders.filter(
                                (value) => value !== auftrag.id
                              )
                            );
                          } else {
                            // Add order to array of selected orders
                            setSelectedOrders([...selectedOrders, auftrag.id]);
                          }
                        }
                      }
                ,
                actions: [
                  React.createElement(React.Fragment, null
                    , !!facility && !hideElevatorLink && (
                      React.createElement(Button, {
                        key: "elevator",
                        icon: React.createElement(Icon, { icon: "fa:external-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}} ),
                        children: t("app:admin.elevators.view_title", {
                          name: facility.get("no") || "",
                        }),
                        onClick: (e) => {
                          e.preventDefault();
                          navigate(`/elevators/${facility.id}`);
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
                      )
                    )
                  ),
                  //   <Button
                  //     key="print"
                  //     type="primary"
                  //     icon={<Icon icon="fa:print" />}
                  //     children={t("app:admin.order.action_print")}
                  //     onClick={() => {
                  //       navigate(`/print-order/${auftrag.id}`);
                  //     }}
                  //   />,
                  React.createElement(Button, {
                    key: "show",
                    type: "primary",
                    // icon={<Icon icon="fa:print" />}
                    children: t("app:admin.order.action_show"),
                    onClick: (e) => {
                      e.preventDefault();
                      navigate(link);
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
                  ),
                ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}

                /* <Link to={link}> */
                , allowSelection ? (
                  React.createElement(Checkbox, {
                    disabled: checkboxDisabled,
                    style: { marginRight: 24 },
                    onClick: (e) => {
                      e.preventDefault();
                    },
                    checked: selectedOrders.includes(auftrag.id), __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
                  )
                ) : null
                , React.createElement(List.Item.Meta, {
                  avatar: 
                    !auftrag.get("local_moreWorkNecessary") &&
                    !!auftrag.get("sys_mailSent") ? (
                      React.createElement(Icon, {
                        icon: "fa:check-double",
                        style: {
                          color: "green",
                          fontSize: "16px",
                          height: "48px",
                          lineHeight: "48px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
                      )
                    ) : auftrag.get("sys_mailSent") ? (
                      React.createElement(Icon, {
                        icon: "fa:check",
                        style: {
                          color: "#ffc107",
                          fontSize: "16px",
                          height: "48px",
                          lineHeight: "48px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
                      )
                    ) : (
                      React.createElement(Icon, {
                        icon: "fa:clock",
                        style: {
                          fontSize: "16px",
                          height: "48px",
                          lineHeight: "48px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
                      )
                    )
                  ,
                  title: 
                    React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
                      , title, " " , React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 198}}, date), " " , React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 198}}, auftrag.type)
                    )
                  ,
                  description: 
                    React.createElement(React.Fragment, null
                      , React.createElement('div', { style: { marginBottom: 8 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}, description1)
                      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 204}}, description2)
                      , reldescription ? (
                        React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 206}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}, reldescription)
                        )
                      ) : null
                      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 210}}
                        , typeof technician !== "undefined" &&
                        technician !== null &&
                        typeof technician.get("name") !== "undefined" &&
                        typeof technician.get("name2") !== "undefined"
                          ? "Techniker: " +
                            technician.get("name2") +
                            " " +
                            technician.get("name")
                          : null
                      )
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
                )
              )
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        )
      )
    );
  }
);

function getOrder(row) {
  if (row instanceof Order) {
    return row;
  }

  if (row instanceof OrderTechnicanRelation) {
    return row.order;
  }

  throw new Error("Invalid row data");
}

function getDescription(row) {
  if (row instanceof Order) {
    return "";
  }

  if (row instanceof OrderTechnicanRelation) {
    return row.get("description");
  }

  throw new Error("Invalid row data");
}

function getLink(
  row,
  linkToMobile,
  auftrag
) {
  if (row instanceof Order) {
    return linkToMobile
      ? `/phone/order/${auftrag.id}`
      : `/orders/${auftrag.id}`;
  }

  if (row instanceof OrderTechnicanRelation) {
    return linkToMobile
      ? `/phone/order/${auftrag.id}?rel=${row.id}`
      : `/orders/${auftrag.id}?rel=${row.id}`;
  }

  return "/";
}

function getFacility(row) {
  if (row instanceof Order) {
    return row.facility;
  }

  if (row instanceof OrderTechnicanRelation) {
    if (typeof row.facility !== "undefined") {
      return row.facility;
    }
    return _optionalChain([row, 'access', _ => _.order, 'optionalAccess', _2 => _2.facility]);
  }

  throw new Error("Invalid row data");
}

function getTechnician(row) {
  if (row instanceof Order) {
    return null;
  }

  if (row instanceof OrderTechnicanRelation) {
    return row.technican;
  }

  throw new Error("Invalid row data");
}

function getDate(row) {
  if (row instanceof Order) {
    return "Eingegangen am: " + formatDate(row.date);
  }

  if (row instanceof OrderTechnicanRelation) {
    return formatDateTime(row.start);
  }

  throw new Error("Invalid row data");
}
